import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";
import { Link } from "react-router-dom";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    // const scrollTo = (id: string) => {
    //   const element = document.getElementById(id) as HTMLDivElement;
    //   element.scrollIntoView({
    //     behavior: "smooth",
    //   });
    //   setVisibility(false);
    // };
    return (
      <>
        {/* <CustomNavLinkSmall>
          <Link to="/">
            <Span>{t("About")}</Span>
          </Link>
        </CustomNavLinkSmall> */}
        {/* <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
          <Span>{t("Mission")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <Span>{t("Product")}</Span>
        </CustomNavLinkSmall> */}
        {/* <CustomNavLinkSmall>
          <Link to="/feedbacks">
            <Span>{t("Feedbacks")}</Span>
          </Link>
        </CustomNavLinkSmall> */}
        <CustomNavLinkSmall style={{ width: "180px" }}>
          <Link to="/feedbacks">
            <Span>
              <Button>{t("Feedbacks")}</Button>
            </Span>{" "}
          </Link>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width="101px" height="64px" />
          </LogoContainer>
          {/* <MenuItem /> */}
          {/* <Burger onClick={toggleButton}>
            <Outline />
          </Burger> */}
        </Row>
        {/* <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer> */}
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
